import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import './CartProduct.scss';

const CartProduct = (props) => {
  const { cartPrice, cartQuantity, catUOM, description, imageUrl, ordered } = props;
  const itemAmount = (ordered) ? cartQuantity : 0;
  const cartPricePretty = (catUOM === '$$$') ? `$${cartPrice.toFixed(2)}` : null;

  return (
    <FormGroup className="CartProduct item item-block item-md">
      <div className="thumbnail start">
        <img src={imageUrl} alt="" />
      </div>
      <div className="item-inner">
        <p>{description}</p>
      </div>
      <p className="item-end">{cartPricePretty || itemAmount}</p>
    </FormGroup>
  );
};

CartProduct.propTypes = {
  cartPrice: PropTypes.any,
  cartQuantity: PropTypes.any,
  catUOM: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  ordered: PropTypes.bool,
};

CartProduct.defaultProps = {
  cartPrice: null,
  cartQuantity: null,
  catUOM: null,
  description: null,
  imageUrl: null,
  ordered: false,
};

export default CartProduct;
