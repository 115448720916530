import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './CartSummary.scss';
import MainNavbar from '../../components/MainNavbar/MainNavbar';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import CartCategoriesList from '../../components/CartCategoriesList/CartCategoriesList';
import CancelButton from '../../components/CancelButton/CancelButton';

function CartSummary(props) {
  const { categories, history, totalCartQty } = props;
  const haveItemsInTheCart = (totalCartQty > 0);

  if (categories.length === 0) {
    history.replace('/item-categories');
  }

  return (
    <div className="CartSummary">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container-fluid">
          {haveItemsInTheCart ? (
            <>
              <CartCategoriesList categories={categories} />
              <Button block color="success " onClick={() => history.push('/order-info')}>
                <div className="icon">
                  <i className="fas fa-check" />
                </div>
                Continue
              </Button>
            </>
          ) : (
            <div className="m-b-1">You have not added any items to your cart yet.</div>
          )}
          <CancelButton className="m-t-2" />
        </div>
      </div>
    </div>
  );
}

CartSummary.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  totalCartQty: PropTypes.number,
};

CartSummary.defaultProps = {
  categories: [],
  history: null,
  totalCartQty: 0,
};

const mapStateToProps = (state) => ({
  categories: ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'),
  totalCartQty: ItemCategoriesDataSelectors.returnTotalCartCountFromState(state),
});

export default connect(mapStateToProps)(withRouter(CartSummary));
