import React, { useLayoutEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import MainNavbar from '../../components/MainNavbar/MainNavbar';

import './ItemCategories.scss';
import { ItemCategoriesDataOperations, ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { StoresDataSelectors } from '../../state/ducks/StoresData';
import ItemCategoriesList from '../../components/ItemCategoriesList/ItemCategoriesList';
import CancelButton from '../../components/CancelButton/CancelButton';

function ItemCategories(props) {
  const { categories, fetchAllCategoriesIfNeeded, history, isReady, selectedStore, totalCartQty } = props;
  const haveItemCategories = (categories.length > 0);
  const haveItemsInTheCart = (totalCartQty > 0);

  if (selectedStore == null) {
    history.replace('/stores');
  }

  useLayoutEffect(() => {
    if (selectedStore != null) {
      fetchAllCategoriesIfNeeded(selectedStore.store_no);
    }
  }, [fetchAllCategoriesIfNeeded, selectedStore]);

  const goToItemDetails = (catId, productId) => {
    history.push(`/item-details/${catId}/${productId}`);
  };

  return (
    <div className="ItemCategories">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer footer-spacer">
        <div className="container-fluid">
          {isReady ? (
            <>
              {haveItemCategories ? (
                <>
                  <p>Please select items for your order that are available from your {selectedStore.state} WIC Benefits</p>
                  <ItemCategoriesList categories={categories} onProductClick={goToItemDetails} />
                </>
              ) : (
                <div className="m-t-2">No categories to purchase from were found for your store.</div>
              )}
            </>
          ) : (
            <span className="loadingSpinner"><i className="fas fa-spinner fa-spin" /></span>
          )}
        </div>
        <footer>
          <div className="toolbar container-fluid">
            <Row>
              <Col xs="5">
                <CancelButton />
              </Col>
              <Col xs="7" className="text-right">
                {haveItemsInTheCart && (
                  <Button color="primary" onClick={() => history.push('/cart-summary')}>
                    <div className="icon">
                      <i className="fas fa-check" />
                    </div>
                    Done with Products
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </footer>
      </div>
    </div>
  );
}

ItemCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  fetchAllCategoriesIfNeeded: PropTypes.func,
  history: PropTypes.object,
  isReady: PropTypes.bool,
  selectedStore: PropTypes.object,
  totalCartQty: PropTypes.number,
};

ItemCategories.defaultProps = {
  categories: [],
  fetchAllCategoriesIfNeeded: null,
  history: null,
  isReady: false,
  selectedStore: null,
  totalCartQty: 0,
};

const mapStateToProps = (state, props) => ({
  categories: ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'),
  isReady: ItemCategoriesDataSelectors.isReady(state),
  selectedStore: StoresDataSelectors.returnItemFromState(state, 'selectedStore'),
  totalCartQty: ItemCategoriesDataSelectors.returnTotalCartCountFromState(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchAllCategoriesIfNeeded: (storeNo) => dispatch(ItemCategoriesDataOperations.fetchAllCategoriesIfNeeded(storeNo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemCategories));
