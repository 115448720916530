import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'reactstrap';

import './MainNavbar.scss';
import BackButton from '../BackButton/BackButton';

function MainNavbar(props) {
  const { showBackButton } = props;

  return (
    <Navbar color="dark" dark expand="md" className="MainNavbar">
      {showBackButton && (
        <BackButton />
      )}
      <div className="brand-centered">
        <NavbarBrand href="/">
          <img src="/img/app-logo.png" alt="Prime Time Nutrition" />
        </NavbarBrand>
      </div>
    </Navbar>
  );
}

MainNavbar.propTypes = {
  showBackButton: PropTypes.bool,
};

MainNavbar.defaultProps = {
  showBackButton: true,
};

export default MainNavbar;
