import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './CartCategory.scss';
import CartProduct from '../CartProduct/CartProduct';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';

const CartCategory = (props) => {
  const { cartQtyByProduct, description, imageUrl, products, uom } = props;
  const items = [];

  let catCartBftQty = 0;
  let catCartPrice = 0;
  let catCartQty = 0;

  // Put our products in order based on sequence or ordered amount
  const productsInOrder = Object.entries(products).sort((a, b) => {
    const aCartQty = parseInt(cartQtyByProduct[a.prodkey]) || 0;
    const bCartQty = parseInt(cartQtyByProduct[b.prodkey]) || 0;

    const aHasQty = (aCartQty > 0) ? 1 : 0;
    const bHasQty = (bCartQty > 0) ? 1 : 0;

    return bHasQty - aHasQty || a.seq - b.seq;
  });

  productsInOrder.forEach(([, item]) => {
    const { bftqty, descript: itemDescription, image_url: itemImageURL, price: itemPrice, prodkey } = item;

    const cartQty = parseInt(cartQtyByProduct[prodkey]) || 0;
    const cartBftQty = cartQty * bftqty;
    const cartPrice = cartQty * itemPrice;

    catCartBftQty += cartBftQty;
    catCartPrice += cartPrice;
    catCartQty += cartQty;

    if (cartQty > 0) {
      items.push(
        <CartProduct
          key={prodkey}
          imageUrl={itemImageURL || imageUrl}
          description={itemDescription}
          cartPrice={cartPrice}
          cartQuantity={cartQty}
          ordered
          catUOM={uom}
          className="item item-block item-md"
        />,
      );
    }
  });

  // Add our ordered totals
  const productsInCatOrdered = catCartQty > 0;
  const price = (productsInCatOrdered && catCartPrice) ? catCartPrice : 0;
  const qty = (productsInCatOrdered && catCartBftQty) ? catCartBftQty : 0;
  const orderedAmountText = (uom === '$$$') ? `$${price.toFixed(2)}` : `${qty} ${uom}`;

  if (qty === 0) {
    return (<></>);
  }

  return (
    <div role="link" className="CartCategory">
      <div className="divider">
        <h2>
          {description}
          <span className="cart-qty">{orderedAmountText}</span>
        </h2>
      </div>
      <div className="list sub-list">
        {items}
      </div>
    </div>
  );
};

CartCategory.propTypes = {
  cartQtyByProduct: PropTypes.object,
  catId: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  uom: PropTypes.string,
};

CartCategory.defaultProps = {
  cartQtyByProduct: {},
  catId: null,
  description: null,
  imageUrl: null,
  products: [],
  uom: null,
};

const mapStateToProps = (state) => ({
  cartQtyByProduct: ItemCategoriesDataSelectors.returnCartQtyByProductFromState(state),
});

export default connect(mapStateToProps)(withRouter(CartCategory));
