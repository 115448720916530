import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
// import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import * as reducers from './ducks';

export default function configureStore(initialState) {
  const rootReducer = combineReducers(reducers);

  // const persistConfig = {
  //   key: 'root',
  //   storage,
  // };

  // const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    // persistedReducer,
    rootReducer,
    initialState,
    compose(
      applyMiddleware(reduxThunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
    ),
  );

  // const persistor = persistStore(store);
  // return { store, persistor };
  return { store };
}
