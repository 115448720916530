import { forkJoin } from 'rxjs';
import * as types from './types';

import { actions } from '../../utils';
import { returnItemFromState } from './selectors';
import { BASE_API_URL } from '../../../constants';

const superagent = require('superagent');

// Create our shorthand action dispatches
export const receiveRequest = (scope, data) => actions.returnDispatchSetRequest(types.RECEIVE_ItemCategories_REQUEST, scope, data);
export const setFetching = (scope, fetching) => actions.returnDispatchSetFetching(types.SET_ItemCategories_FETCHING, scope, fetching);
export const setLoading = (scope, loading) => actions.returnDispatchSetLoading(types.SET_ItemCategories_LOADING, scope, loading);
export const toggleBoolean = (scope, toggleDirection) => actions.returnDispatchSetBoolean(types.TOGGLE_ItemCategories_BOOLEAN, scope, toggleDirection);

// Use our shorthand for some basic actions
export const selectProducts = (product) => receiveRequest('selectedProducts', product);

// Define any functions we need to run our dispatches
/**
 * Load all item categories from the API.
 *
 * @returns {Promise<*|*[]>}
 */
async function getCategories() {
  const res = await superagent.get(`${BASE_API_URL}categories`)
    .set('Accept', 'application/json');

  return res.body.result || [];
}

/**
 * Load all products for a category from the API.
 *
 * @param id
 * @param storeNo
 * @returns {Promise<*|*[]>}
 */
async function getCategoryProducts(id, storeNo) {
  const res = await superagent.get(`${BASE_API_URL}category/${id}/products?store=${storeNo}`)
    .set('Accept', 'application/json');

  return res.body.result || [];
}

// Now create our actions which require us to dispatch back a fetch
/**
 *  Fetch all of our initial data
 *
 * @param storeNo
 * @returns {Function}
 */
export const fetchAllCategories = (storeNo) => (dispatch) => {
  // Mark that we are fetching things
  dispatch(setFetching('categories', true));
  dispatch(receiveRequest('lastStore', storeNo));

  return getCategories().then((categories) => {
    // console.log('Category:Success => ', categories);
    if (categories.length > 0) {
      // Load products for each category
      const calls = [];

      categories.forEach((cat, catIndex) => {
        calls.push(getCategoryProducts(cat.WICCAT, storeNo));
        categories[catIndex].products = [];
      });

      forkJoin(calls).subscribe(
        (data) => {
          data.forEach((catResult, catIndex) => {
            if (catResult.length > 0) {
              catResult.forEach((product) => {
                product.catUOM = categories[catIndex].UOM;
                categories[catIndex].products.push(product);
              });
            }
          });
        },
        (err) => console.log(`error ${err}`),
        () => {
          dispatch(receiveRequest('categories', categories));
          dispatch(receiveRequest('selectedProducts', null));

          // Mark that we are finished fetching things
          dispatch(setFetching('categories', false));
        },
      );
    }
  });
};

/**
 * Determines whether we need to fetch data from a section
 *
 * @param state
 * @param storeNo
 * @returns {boolean}
 */
function shouldFetchAllCategories(state, storeNo) {
  const data = returnItemFromState(state, 'categories');
  const lastStore = returnItemFromState(state, 'lastStore');

  return (!data || lastStore !== storeNo);
}

/**
 *  Fetch all of our initial data
 *
 * @param storeNo
 * @returns {Function}
 */
export const fetchAllCategoriesIfNeeded = (storeNo) => (dispatch, getState) => {
  if (shouldFetchAllCategories(getState(), storeNo)) {
    dispatch(fetchAllCategories(storeNo));
  }
};
