import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import './ItemCategory.scss';
import Product from '../Product/Product';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';

const ItemCategory = (props) => {
  const { cartQtyByProduct, catId, description, imageUrl, isOpen, onProductClick, onToggleClick, products, uom } = props;
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const items = [];

  let catCartBftQty = 0;
  let catCartPrice = 0;
  let catCartQty = 0;

  // Put our products in order based on sequence or ordered amount
  const productsInOrder = Object.entries(products).sort((a, b) => {
    const aCartQty = parseInt(cartQtyByProduct[a.prodkey]) || 0;
    const bCartQty = parseInt(cartQtyByProduct[b.prodkey]) || 0;

    const aHasQty = (aCartQty > 0) ? 1 : 0;
    const bHasQty = (bCartQty > 0) ? 1 : 0;

    return bHasQty - aHasQty || a.seq - b.seq;
  });

  productsInOrder.forEach(([productIndex, item]) => {
    const { bftqty, descript: itemDescription, image_url: itemImageURL, price: itemPrice, prodkey } = item;

    const cartQty = parseInt(cartQtyByProduct[prodkey]) || 0;
    const cartBftQty = cartQty * bftqty;
    const cartPrice = cartQty * itemPrice;

    catCartBftQty += cartBftQty;
    catCartPrice += cartPrice;
    catCartQty += cartQty;

    items.push(
      <Product
        key={prodkey}
        imageUrl={itemImageURL || imageUrl}
        description={itemDescription}
        cartPrice={cartPrice}
        cartQuantity={cartQty}
        ordered={cartQty > 0}
        onProductClick={() => onProductClick(catId, prodkey)}
        catUOM={uom}
        className="item item-block item-md"
      />,
    );
  });

  // Add our ordered totals
  const productsInCatOrdered = catCartQty > 0;
  const price = (productsInCatOrdered && catCartPrice) ? catCartPrice : 0;
  const qty = (productsInCatOrdered && catCartBftQty) ? catCartBftQty : 0;
  const orderedAmountText = (uom === '$$$') ? `$${price.toFixed(2)}` : `${qty} ${uom}`;

  return (
    <div role="link" className={classNames('ItemCategory', { 'd-none': items.length === 0 })}>
      <div className="divider" onClick={onToggleClick}>
        <h2>
          <span className="toggleIcon item-start">
            <div className="icon">
              <i className={classNames('fas', { 'fa-caret-down': !isOpen, 'fa-caret-up': isOpen })} />
            </div>
          </span>
          {description}
          <span className="cart-qty">{orderedAmountText}</span>
        </h2>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="list sub-list">
          {items}
        </div>
      </Collapse>
    </div>
  );
};

ItemCategory.propTypes = {
  cartQtyByProduct: PropTypes.object,
  catId: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  onProductClick: PropTypes.func,
  onToggleClick: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.object),
  uom: PropTypes.string,
};

ItemCategory.defaultProps = {
  cartQtyByProduct: {},
  catId: null,
  description: null,
  imageUrl: null,
  isOpen: false,
  onProductClick: null,
  onToggleClick: null,
  products: [],
  uom: null,
};

const mapStateToProps = (state, props) => ({
  cartQtyByProduct: ItemCategoriesDataSelectors.returnCartQtyByProductFromState(state),
});

export default connect(mapStateToProps)(withRouter(ItemCategory));
