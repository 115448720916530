import Moment from 'moment-timezone';
import { DOW_ABBREV } from '../../../constants';

/**
 * Return the store controls section of the state.
 *
 * @param state
 */
export function returnMySliceOfState(state) {
  return state.StoresData;
}

/**
 * Check if we are fetching any items in the store.
 *
 * @param state
 * @returns boolean
 */
export function isFetching(state) {
  const curState = returnMySliceOfState(state);

  return (curState.stores_isFetching);
}

/**
 * Check if we are loading any items in the store.
 *
 * @param state
 * @returns boolean
 */
export function isLoading(state) {
  // const curState = returnMySliceOfState(state);

  // return ( curState.stores_isFetching);
  return false;
}

/**
 * Check if we are ready to display.
 *
 * @param state
 * @returns boolean
 */
export function isReady(state) {
  return (!isFetching(state) && !isLoading(state));
}

/**
 * Return an item from our state object.
 *
 * @param state
 * @param item
 * @returns {*}
 */
export function returnItemFromState(state, item) {
  const curState = returnMySliceOfState(state);
  return curState[item];
}

/**
 * Return whether our store allows for curbside pickup.
 *
 * @param state
 * @returns boolean
 */
export function returnSelectedStoreAllowsCurbSidePickUp(state) {
  const selectedStore = returnItemFromState(state, 'selectedStore');
  const { allows_curbside_pick_up = '0' } = selectedStore || {};

  return (allows_curbside_pick_up === '1');
}

/**
 * Return an array of the valid pickup dates for the selected store.
 *
 * @param state
 * @returns array
 */
export function returnSelectedStorePickupDates(state) {
  const selectedStore = returnItemFromState(state, 'selectedStore');
  const selectedStoreTimesByDoW = returnItemFromState(state, 'selectedStoreTimesByDoW');
  const { valid_timezones: storeTimezone = 'America/Los_Angeles' } = selectedStore || {};
  const currentMoment = Moment().tz(storeTimezone);
  const todayMoment = Moment().tz(storeTimezone);
  const tomorrowMoment = Moment().tz(storeTimezone).add(1, 'day');
  const pickupDates = [];
  const holidays = [
    // '2020-10-26', // Thanksgiving
    // '2020-12-25', // Christmas
  ];
  const { endHour: endHourForToday, endMinute: endMinuteForToday, start: startForToday, startHour: startHourForToday, startMinute: startMinuteForToday } = selectedStoreTimesByDoW[DOW_ABBREV[todayMoment.day()]];
  const { start: startForTomorrow, startHour: startHourForTomorrow, startMinute: startMinuteForTomorrow } = selectedStoreTimesByDoW[DOW_ABBREV[tomorrowMoment.day()]];
  const todayIsAHoliday = holidays.indexOf(todayMoment.format('YYYY-MM-DD')) !== -1;
  const tomorrowIsAHoliday = holidays.indexOf(tomorrowMoment.format('YYYY-MM-DD')) !== -1;

  // If we are closed for both days then stop now
  if ((startForToday === 'CLSD' && startForTomorrow === 'CLSD') || (todayIsAHoliday && tomorrowIsAHoliday)) {
    return pickupDates;
  }

  // All orders must be at least 30 minutes from now
  let minDateMoment = currentMoment.add(30, 'minutes');
  let maxDateTimeForTodayMoment = null;
  let maxDate = null;

  // Handle dates and time for today
  if (startForToday !== 'CLSD' && !todayIsAHoliday) {
    const minDateTimeForTodayMoment = todayMoment.hours(startHourForToday).minutes(startMinuteForToday);
    maxDateTimeForTodayMoment = todayMoment.hours(endHourForToday).minutes(endMinuteForToday);

    // Now make sure that our minutes are either 0 or 30
    if (minDateMoment.minutes() < 30) {
      minDateMoment = minDateMoment.minutes(30);
    } else if (minDateMoment.minutes() > 30) {
      minDateMoment = minDateMoment.add(1, 'hour').minutes(0);
    }

    // No orders before the store opens
    if (minDateMoment.isBefore(minDateTimeForTodayMoment)) {
      minDateMoment = minDateTimeForTodayMoment;
    }
  }

  // Handle dates and time for tomorrow
  if (startForTomorrow !== 'CLSD' && !tomorrowIsAHoliday) {
    const minDateTimeForTomorrowMoment = tomorrowMoment.hours(startHourForTomorrow).minutes(startMinuteForTomorrow);

    // Is the store currently closed? If so then you can't order until tomorrow
    if (maxDateTimeForTodayMoment === null || minDateMoment.isAfter(maxDateTimeForTodayMoment)) {
      minDateMoment = minDateTimeForTomorrowMoment;
    }

    maxDate = minDateTimeForTomorrowMoment.format('LL');
  }

  // Handle Today
  const minDate = minDateMoment.format('LL');

  pickupDates.push(minDate);

  // Handle Tomorrow
  if (maxDate !== null && minDate !== maxDate) {
    pickupDates.push(maxDate);
  }

  return pickupDates;
}

/**
 * Return an array of the valid pickup time for a date.
 *
 * @param state
 * @param {string} date
 * @param {string|undefined} format
 * @returns object
 */
export function returnSelectedStorePickupTimesForDate(state, date, format) {
  if (date == null) {
    return {};
  }

  const selectedStore = returnItemFromState(state, 'selectedStore');
  const selectedStoreTimesByDoW = returnItemFromState(state, 'selectedStoreTimesByDoW');
  const { valid_timezones: storeTimezone = 'America/Los_Angeles' } = selectedStore || {};
  const currentMoment = Moment().tz(storeTimezone);
  const minMomentForToday = currentMoment.add(30, 'minutes');
  const currentDOW = currentMoment.day();
  const selectedDOW = Moment(date, format).day();
  const forToday = (selectedDOW === currentDOW);

  let { end, endHour, endMinute, start, startHour, startMinute } = selectedStoreTimesByDoW[DOW_ABBREV[selectedDOW]];
  const pickupTimes = {};

  // If we are closed then stop now
  if (start === 'CLSD' || end === 'CLSD') {
    return pickupTimes;
  }

  if (forToday) {
    // Make sure that we don't start listing hours before now
    const currentHour = minMomentForToday.hour();
    const currentMinute = minMomentForToday.minute();

    if (startHour < currentHour) {
      startHour = currentHour;
    }
    if (startHour === currentHour && startMinute < currentMinute) {
      startMinute = currentMinute;
    }
  }

  if (startHour <= endHour) {
    for (let hour = startHour; hour <= endHour; hour += 1) {
      const hourToAdd = (hour > 12) ? hour - 12 : hour;
      const amOrPM = (hour >= 12) ? 'PM' : 'AM';
      const paddedHour = hour.toString().padStart(2, '0');

      if (hour > startHour || (hour === startHour && startMinute <= 0)) {
        pickupTimes[`${paddedHour}:00`] = `${hourToAdd}:00 ${amOrPM}`;
      }

      if ((hour === startHour && startMinute < 30) || hour !== endHour || (hour === endHour && endMinute >= 30)) {
        pickupTimes[`${paddedHour}:30`] = `${hourToAdd}:30 ${amOrPM}`;
      }
    }
  }

  return pickupTimes;
}
