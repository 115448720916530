import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import './Product.scss';

const Product = (props) => {
  const { cartQuantity, description, imageUrl, onProductClick, ordered } = props;
  const itemAmount = (ordered) ? cartQuantity : 0;

  return (
    <FormGroup onClick={onProductClick} className="Product item item-block item-md">
      <div className="thumbnail start">
        <img src={imageUrl} alt="" />
      </div>
      <div className="item-inner">
        <p>{description}</p>
      </div>
      <p className="item-end">{itemAmount}</p>
      <div className="icon item-end">
        <i className="fas fa-caret-right" />
      </div>
    </FormGroup>
  );
};

Product.propTypes = {
  cartQuantity: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  onProductClick: PropTypes.func,
  ordered: PropTypes.bool,
};

Product.defaultProps = {
  cartQuantity: null,
  description: null,
  imageUrl: null,
  onProductClick: null,
  ordered: false,
};

export default Product;
