import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

import PrivacyPolicyModal from '../../components/PrivacyPolicyModal/PrivacyPolicyModal';
import CopyrightNotice from '../../components/CopyrightNotice/CopyrightNotice';

import './Home.scss';

function Home(props) {
  const { history } = props;

  return (
    <div className="Home">
      <header>
        <div className="logo-wrapper">
          <img src="/img/app-logo.png" alt="Prime Time Nutrition" />
        </div>
      </header>

      <PrivacyPolicyModal />

      <div className="safeAreaWrapper">
        <div className="content-wrapper">
          <Button color="primary" size="lg" block onClick={() => history.push('/stores')}>
            <i className="fas fa-search" />
            Search
          </Button>
          <p className="text-center">Locate the nearest Prime Time Nutrition or Quickeroo store to start your order.</p>
        </div>
      </div>

      <CopyrightNotice />
    </div>
  );
}

Home.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Home);
