import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import './StoresList.scss';

const StoresList = ({ stores, onClickStore }) => {
  const storeDetails = [];

  Object.entries(stores).forEach(([index, store]) => {
    storeDetails.push(
      <FormGroup
        key={store.uuid}
        className="list-view-item"
        onClick={() => onClickStore(index)}
      >
        <div className="icon item-start">
          <i className="fas fa-car" />
          <span>{store.distance || 0} mi.</span>
        </div>
        <div className="item-inner">
          <strong>{store.display_name || 'Store Name'}</strong><br />
          {store.address || 'street'}<br />
          {store.city || 'City'}, {store.state || 'ST'} {store.zip || '0000'}
        </div>
        <div className="icon item-end">
          <i className="fas fa-caret-right" />
        </div>
      </FormGroup>,
    );
  });

  return (
    <div className="StoresList">
      {storeDetails}
    </div>
  );
};

StoresList.propTypes = {
  onClickStore: PropTypes.func,
  stores: PropTypes.arrayOf(PropTypes.object),
};

StoresList.defaultProps = {
  onClickStore: null,
  stores: [],
};

export default StoresList;
