import React from 'react';
import { withRouter } from 'react-router-dom';

import './BackButton.scss';
import PropTypes from 'prop-types';

function BackButton(props) {
  const { history } = props;

  return (
    <button type="button" className="backButton" onClick={history.goBack}>
      <i className="fas fa-arrow-left" />
    </button>
  );
}

BackButton.propTypes = {
  history: PropTypes.object,
};

export default withRouter(BackButton);
