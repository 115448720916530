import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert2-react'; // eslint-disable-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.css';

import './CancelButton.scss';

function CancelButton(props) {
  const { className, history } = props;
  const [show, setShow] = useState(false);

  return (
    <>
      <SweetAlert
        show={show}
        title="Cancel Order"
        type="error"
        text="Are you sure you want to cancel your order?"
        showCancelButton
        confirmButtonText="Yes, Cancel It"
        cancelButtonText="No, Keep Working"
        onConfirm={() => history.replace('/')}
        onCancel={() => setShow(false)}
      />
      <Button color="danger" className={classNames('cancelButton', className)} onClick={() => setShow(true)}>
        <div className="icon">
          <i className="fas fa-times" />
        </div>
        Cancel
      </Button>
    </>
  );
}

CancelButton.defaultProps = {
  className: '',
  history: null,
};

CancelButton.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(CancelButton);
