import React from 'react';
import { Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment-timezone';

import './ThankYou.scss';

import MainNavbar from '../../components/MainNavbar/MainNavbar';
import { StoresDataSelectors } from '../../state/ducks/StoresData';
import { OrdersDataSelectors } from '../../state/ducks/OrdersData';

function ThankYou(props) {
  const { selectedStore = {}, order = {} } = props;
  const { store_no = '', phone_number = '', valid_timezones: storeTimezone = 'America/Los_Angeles' } = selectedStore || {};
  const { phone_number: orderPhoneNumber = '', pickup_at = '' } = order;
  const confirmationNumber = orderPhoneNumber.slice(orderPhoneNumber.length - 4);
  const pickupDateAndTimeMoment = Moment(pickup_at).tz(storeTimezone);
  const pickupDateAndTime = `${pickupDateAndTimeMoment.format('dddd MMMM DD, YYYY')} at ${pickupDateAndTimeMoment.format('h:mm A')}`;

  return (
    <div className="ThankYou">
      <MainNavbar showBackButton={false} />
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container-fluid">
          <h1>Thank You!</h1>
          <div>
            <p>Thank you for your order, your products will be waiting for you in store.</p>
            <h3>Your Confirmation Number is {confirmationNumber}</h3>
            <p>Please Arrive To The Store And Bring Your WIC CARD</p>
            <p>{pickupDateAndTime}</p>
            <br />
            <p>If you need to cancel or change your order please call</p>
            <p>Primetime Nutrition Store #{store_no} - {phone_number}</p>
            <br />
            <p>Please provide your confirmation number and vehicle description for quicker service.</p>
            <br />
            <p>WIC requires the use of the eWIC card and PIN to process a purchase.</p>
          </div>

          <Button block tag={Link} to="/">
            <div className="icon">
              <i className="fas fa-home" />
            </div>
            Home
          </Button>
        </div>
      </div>
    </div>
  );
}

ThankYou.propTypes = {
  order: PropTypes.object,
  selectedStore: PropTypes.object,
};

ThankYou.defaultProps = {
  order: {},
  selectedStore: null,
};

const mapStateToProps = (state) => ({
  order: OrdersDataSelectors.returnItemFromState(state, 'order'),
  selectedStore: StoresDataSelectors.returnItemFromState(state, 'selectedStore'),
});

export default connect(mapStateToProps)(withRouter(ThankYou));
