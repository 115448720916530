import React from 'react';
import PropTypes from 'prop-types';
import './CartCategoriesList.scss';
import CartCategory from '../CartCategory/CartCategory';

const CartCategoriesList = ({ categories }) => {
  const itemCats = [];

  Object.entries(categories).forEach(([, group]) => {
    const { WICCAT, DESCRIPT, image_url, products, UOM } = group;

    itemCats.push(
      <CartCategory
        key={WICCAT}
        catId={WICCAT}
        description={DESCRIPT}
        imageUrl={image_url}
        products={products}
        uom={UOM}
      />,
    );
  });

  return (
    <div className="CartCategoriesList">
      {itemCats}
    </div>
  );
};

CartCategoriesList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
};

CartCategoriesList.defaultProps = {
  categories: [],
};

export default CartCategoriesList;
