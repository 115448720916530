import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { Button, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import SweetAlert from 'sweetalert2-react'; // eslint-disable-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.css';
import './ItemDetails.scss';

import MainNavbar from '../../components/MainNavbar/MainNavbar';
import { useInput } from '../../hooks/useInput';
import { ItemCategoriesDataOperations, ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { StoresDataSelectors } from '../../state/ducks/StoresData';

function ItemDetails(props) {
  const { categories, history, selectProducts, selectedProducts } = props;
  const { catId, productId } = useParams();
  const [error, setError] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const { value: quantity, bind: bindQuantity, setValue: setQuantity } = useInput(1);

  let itemDescriptionSuffix = '';
  const { image_url, descript = 'Item Name' } = selectedItem;

  if (categories.length === 0) {
    history.replace('/stores');
  }

  useLayoutEffect(() => {
    // See if the product is in the selectedProducts set
    let productFoundInSelectedProducts = null;

    if (selectedProducts != null && selectedProducts.products != null) {
      const { products = [] } = selectedProducts.products;

      // Look for product already being in the cart
      products.forEach((product) => {
        if (productId.toString() === product.prodkey.toString()) {
          productFoundInSelectedProducts = product;
        }
      });
    }

    if (productFoundInSelectedProducts != null) {
      // We've added this to the cart before so lets access it
      setSelectedItem(productFoundInSelectedProducts);
      setQuantity(parseInt(productFoundInSelectedProducts.cart_quantity));
    } else {
      // We need to find it in the main categories object
      Object.entries(categories).forEach(([, cat]) => {
        const { WICCAT, products } = cat;

        if (catId.toString() === WICCAT.toString()) {
          for (const product of products) {
            const { prodkey } = product;

            if (productId.toString() === prodkey.toString()) {
              setSelectedItem(product);
              setQuantity(1);
            }
          }
        }
      });
    }
  }, [categories, catId, productId, selectedProducts, setQuantity]);

  if (selectedItem.catUOM === '$$$') {
    const total = (selectedItem.price * quantity).toFixed(2);
    itemDescriptionSuffix = `$${total}`;
  }

  const options = [];

  for (let qty = 0; qty <= 25; qty += 1) {
    options.push(<option value={qty} key={qty}>{qty}</option>);
  }

  const addItem = () => {
    if (quantity < 0) {
      setError('Please enter a Quantity to add this Product.');
      return;
    }
    if (quantity > selectedItem.maxQty) {
      setError(`This quantity is not available. The max quantity for this product is ${selectedItem.maxQty}.`);
      return;
    }

    selectedItem.cart_quantity = quantity;

    let cart = {
      products: [],
    };
    let products = [];

    if (selectedProducts != null && selectedProducts.products != null) {
      cart = selectedProducts;
      products = selectedProducts.products || [];

      // Look for product already being in the cart
      products.forEach((product, index) => {
        if (product.prodkey === selectedItem.prodkey) {
          products.splice(index, 1);
        }
      });
    }

    // Add the product
    products.push(selectedItem);

    cart.products = products;

    selectProducts(cart);
    history.push('/item-categories');
  };

  return (
    <div className="ItemDetails">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <SweetAlert
        show={error.length > 0}
        type="error"
        text={error}
        onConfirm={() => setError('')}
      />
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container-fluid">
          <div className="list add-item-list">
            <div className="thumbnail start">
              <img src={image_url} alt="" />
            </div>
            <h3 className="item-name">
              {descript}
              {itemDescriptionSuffix}
            </h3>

            <FormGroup className="add-item row">
              <Label className="col-sm-2 col-form-label">Quantity:</Label>
              <div className="col-sm-10">
                <select className="form-control" {...bindQuantity}>
                  {options}
                </select>
              </div>
            </FormGroup>
            <Button color="primary" className="add-item-btn" block onClick={addItem}>Update Order</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

ItemDetails.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  fetchAllCategoriesIfNeeded: PropTypes.func,
  history: PropTypes.object,
  isReady: PropTypes.bool,
  selectProducts: PropTypes.func,
  selectedProducts: PropTypes.object,
  selectedStore: PropTypes.object,
};

ItemDetails.defaultProps = {
  categories: [],
  history: null,
  selectProducts: null,
  selectedProducts: null,
};

const mapStateToProps = (state, props) => ({
  categories: ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'),
  selectedProducts: ItemCategoriesDataSelectors.returnItemFromState(state, 'selectedProducts'),
  selectedStore: StoresDataSelectors.returnItemFromState(state, 'selectedStore'),
});

const mapDispatchToProps = (dispatch, props) => ({
  selectProducts: (product) => dispatch(ItemCategoriesDataOperations.selectProducts(product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemDetails));
