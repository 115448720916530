/**
 * Return the item controls section of the state.
 *
 * @param state
 */
export function returnMySliceOfState(state) {
  return state.ItemCategoriesData;
}

/**
 * Check if we are fetching any itemCategories in the item.
 *
 * @param state
 * @returns boolean
 */
export function isFetching(state) {
  const curState = returnMySliceOfState(state);

  return (curState.categories_isFetching);
}

/**
 * Check if we are loading any itemCategories in the item.
 *
 * @param state
 * @returns boolean
 */
export function isLoading(state) {
  // const curState = returnMySliceOfState(state);

  // return ( curState.categories_isLoading);
  return false;
}

/**
 * Check if we are ready to display.
 *
 * @param state
 * @returns boolean
 */
export function isReady(state) {
  return (!isFetching(state) && !isLoading(state));
}

/**
 * Return an item from our state object.
 *
 * @param state
 * @param item
 * @returns {*}
 */
export function returnItemFromState(state, item) {
  const curState = returnMySliceOfState(state);
  return curState[item];
}

/**
 * Get the total cart quantity for the selected products.
 *
 * @param state
 * @returns {number}
 */
export function returnTotalCartCountFromState(state) {
  let totalCartQty = 0;
  const selectedProducts = returnItemFromState(state, 'selectedProducts');

  if (selectedProducts != null && selectedProducts.products != null) {
    // Look for product already being in the cart
    selectedProducts.products.forEach((product, index) => {
      totalCartQty += product.cart_quantity;
    });
  }

  return totalCartQty;
}

/**
 * Get the total cart quantity for the selected products.
 *
 * @param state
 * @returns {number}
 */
export function returnCartQtyByProductFromState(state) {
  const cartQtyByProduct = {};
  const selectedProducts = returnItemFromState(state, 'selectedProducts');

  if (selectedProducts != null && selectedProducts.products != null) {
    // Look for product already being in the cart
    selectedProducts.products.forEach((product, index) => {
      cartQtyByProduct[product.prodkey] = product.cart_quantity;
    });
  }

  return cartQtyByProduct;
}
