/**
 * Return a Boolean action
 *
 * @param type
 * @param scope
 * @param boolValue
 * @returns {{type: *, payload: {scope: *, boolValue: *}}}
 */
export const setBoolean = (type, scope, boolValue) => ({
  type,
  payload: {
    scope,
    boolValue,
  },
});

/**
 * Return a Fetching action
 *
 * @param type
 * @param scope
 * @param fetching
 * @returns {{type: *, payload: {scope: *, fetching: *}}}
 */
export const setFetching = (type, scope, fetching) => ({
  type,
  payload: {
    scope,
    fetching,
  },
});

/**
 * Return a Loading action
 *
 * @param type
 * @param scope
 * @param loading
 * @returns {{type: *, payload: {scope: *, loading: *}}}
 */
export const setLoading = (type, scope, loading) => ({
  type,
  payload: {
    scope,
    loading,
  },
});

/**
 * Return a Request action
 *
 * @param type
 * @param scope
 * @param data
 * @returns {{type: *, payload: {scope: *, data: *}}}
 */
export const setRequest = (type, scope, data = null) => ({
  type,
  payload: {
    scope,
    data,
  },
});

/**
 * Return a Boolean Action dispatcher
 *
 * @param type
 * @param scope
 * @param toggleDirection
 * @returns {Function}
 */
export const returnDispatchSetBoolean = (type, scope, toggleDirection) => (dispatch) => {
  dispatch(setBoolean(type, scope, toggleDirection));
};

/**
 * Return a Fetching Action dispatcher
 *
 * @param type
 * @param scope
 * @param fetching
 * @returns {Function}
 */
export const returnDispatchSetFetching = (type, scope, fetching) => (dispatch) => {
  dispatch(setFetching(type, scope, fetching));
};

/**
 * Return a Loading Action dispatcher
 *
 * @param type
 * @param scope
 * @param loading
 * @returns {Function}
 */
export const returnDispatchSetLoading = (type, scope, loading) => (dispatch) => {
  dispatch(setLoading(type, scope, loading));
};

/**
 * Return a Request Action dispatcher
 *
 * @param type
 * @param scope
 * @param data
 * @returns {Function}
 */
export const returnDispatchSetRequest = (type, scope, data) => (dispatch) => {
  dispatch(setRequest(type, scope, data));
};
