import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MainNavbar from '../../components/MainNavbar/MainNavbar';
import './StoreDetails.scss';
import { StoresDataSelectors } from '../../state/ducks/StoresData';

function StoreDetails(props) {
  const { history, selectedStore } = props;
  // console.log(selectedStore);

  if (selectedStore == null) {
    history.replace('/stores');
  }

  const clickedOnCall = () => {
    // console.log('StoreDetails::Clicked on Call....');
    const phoneNumber = selectedStore.phone_number.replace(/[^0-9]/g, '');
    window.location.href = `tel:${phoneNumber}`;
    // this.showAlert('Alert!',' Do you want to Call?');
  };

  const { display_name, description, address, city, state, zip, phone_number } = selectedStore || {};

  return (
    <div className="StoreDetails">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer footer-spacer">
        <div className="container">
          <h2 className="store-no">{display_name || 'Store No'}</h2>

          <div className="store-address-wrapper">
            <p>{address || 'Street'}</p>
            <p>{city || 'City'}, {state || 'ST'} {zip || '0000'}</p>
            <br />
            <p>{phone_number}</p>
          </div>

          <h2 className="store-hours">Store Hours</h2>
          <div className="store-hours-wrapper">
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>

          <div>
            <Row>
              <Col xs="6" className="text-center">
                <Button color="light" onClick={clickedOnCall}>
                  <div className="icon">
                    <i className="fas fa-phone" />
                  </div>
                  Call
                </Button>
              </Col>
              <Col xs="6" className="text-center">
                <Button color="light" onClick={() => history.replace('/')}>
                  <div className="icon">
                    <i className="fas fa-home" />
                  </div>
                  Home
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <footer>
          <div className="toolbar">
            <Button block size="lg" color="primary" onClick={() => history.push('/item-categories')}>
              <div className="icon">
                <i className="fas fa-shopping-cart" />
              </div>
              Start Order
            </Button>
          </div>
        </footer>
      </div>
    </div>
  );
}

StoreDetails.propTypes = {
  history: PropTypes.object,
  selectedStore: PropTypes.object,
};

StoreDetails.defaultProps = {
  history: null,
  selectedStore: null,
};

const mapStateToProps = (state, props) => ({
  selectedStore: StoresDataSelectors.returnItemFromState(state, 'selectedStore'),
});

export default connect(mapStateToProps)(withRouter(StoreDetails));
