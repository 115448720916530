import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'moment-timezone';
import PhoneInput from 'react-phone-input-2';
import { formatPhoneNumber } from 'react-phone-number-input';
import 'react-phone-input-2/lib/style.css';
import SweetAlert from 'sweetalert2-react'; // eslint-disable-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.css';

import './OrderInfo.scss';
import MainNavbar from '../../components/MainNavbar/MainNavbar';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { StoresDataSelectors } from '../../state/ducks/StoresData';
import { useInput } from '../../hooks/useInput';
import { OrdersDataOperations } from '../../state/ducks/OrdersData';
import CancelButton from '../../components/CancelButton/CancelButton';

function OrderInfo(props) {
  const { history } = props;

  const dispatch = useDispatch();

  const cartQtyByProduct = useSelector((state) => ItemCategoriesDataSelectors.returnCartQtyByProductFromState(state));
  const categories = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'));
  const pickupDates = useSelector((state) => StoresDataSelectors.returnSelectedStorePickupDates(state));
  const pickupTimesForDate = useSelector((state) => (date) => StoresDataSelectors.returnSelectedStorePickupTimesForDate(state, date, 'LL'));
  // const selectedProducts = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'selectedProducts'));
  const selectedStore = useSelector((state) => StoresDataSelectors.returnItemFromState(state, 'selectedStore'));
  const selectedStoreAllowsCurbSidePickUp = useSelector((state) => StoresDataSelectors.returnSelectedStoreAllowsCurbSidePickUp(state));
  const totalCartQty = useSelector((state) => ItemCategoriesDataSelectors.returnTotalCartCountFromState(state));

  const { valid_timezones: storeTimezone = 'America/Los_Angeles' } = selectedStore || {};

  if (categories.length === 0) {
    history.replace('/item-categories');
  }

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [storeIsOpen, setStoreIsOpen] = useState(true);
  const [pickupTimes, setPickupTimes] = useState({});

  // Form Elements
  const { value: name, bind: bindName } = useInput('');
  const { value: phoneNo, setValue: setPhoneNo } = useInput('');
  const { value: pickupDate, setValue: setPickupDate } = useInput(Moment.tz(storeTimezone).format('LL'));
  const { value: pickupTime, setValue: setPickupTime } = useInput('');
  const { value: isCurbSidePickUp, setValue: setIsCurbSidePickUp } = useInput('0');
  const { value: sendTextConfirmation, setValue: setSendTextConfirmation } = useInput('1');

  const haveItemsInTheCart = (totalCartQty > 0);
  const usersTime = Moment().tz(storeTimezone).format('MMM D, YYYY h:mm A');

  // Make sure that if our available dates change that we have the right one selected.
  useEffect(() => {
    if (pickupDates.indexOf(pickupDate) === -1 && pickupDates.length > 0) {
      setPickupDate(pickupDates[0]);
      setPickupTimes(pickupTimesForDate(pickupDates[0]));
    }
  }, [pickupDate, pickupDates, pickupTimesForDate, setPickupDate]);

  // Update our pickup times if the pickup date changes.
  useEffect(() => {
    setPickupTimes(pickupTimesForDate(pickupDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupDate, selectedStore, usersTime]);

  // Update our pickup times if the pickup date changes.
  useEffect(() => {
    setStoreIsOpen((pickupDates.length > 0 && Object.entries(pickupTimes).length > 0));
  }, [pickupDates, pickupTimes, setStoreIsOpen]);

  // Update our current pickup times if the pickup date or number of times changes.
  useEffect(() => {
    const times = Object.keys(pickupTimes);

    if (times.length > 0) {
      setPickupTime(times[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupDate, JSON.stringify(pickupTimes), setPickupTime]);

  /**
   * Verify that the selected date is valid.
   *
   * @param showAlerts
   * @returns {string}
   */
  const checkPickupTime = useCallback((showAlerts = true) => {
    let errors = '';

    if (pickupTime.length === 0) {
      return '';
    }

    // console.log({
    //   currentTime: Moment().format('MMM D, YYYY h:mm A'),
    //   currentTimeInLA: Moment().tz('America/Los_Angeles').format('MMM D, YYYY h:mm A'),
    //   currentTimeInStoreTimezone: Moment().tz(storeTimezone).format('MMM D, YYYY h:mm A'),
    //   currentTimeInStoreTimezonePlus30: Moment().tz(storeTimezone).add('30', 'minutes').format('MMM D, YYYY h:mm A'),
    //   selectedTime: pickupTime,
    //   selectedTimeWithDate: `${pickupDate} ${pickupTime}`,
    //   selectedTimeAsMoment: Moment(pickupTime, ['h:m a', 'H:m']).format(),
    //   selectedTimeInLA: Moment.tz(pickupTime, ['h:m a', 'H:m'], 'America/Los_Angeles').format(),
    //   selectedTimeInStoreTimezone: Moment.tz(pickupTime, ['h:m a', 'H:m'], storeTimezone).format(),
    //   isBefore: Moment.tz(pickupTime, ['h:m a', 'H:m'], storeTimezone).isBefore(Moment().tz(storeTimezone).add('30', 'minutes'))
    // });

    if (Moment.tz(`${pickupDate} ${pickupTime}`, ['MMM D, YYYY h:m a', 'MMM D, YYYY H:m'], storeTimezone).isBefore(Moment().tz(storeTimezone).add('30', 'minutes'))) {
      errors = `Please enter a time after ${Moment().tz(storeTimezone).add('30', 'minutes').format('MMM D, YYYY h:mm A')}.`;
    }

    if (showAlerts && errors.length > 0) {
      setError(errors);
    }

    return errors;
  }, [pickupDate, pickupTime, storeTimezone, usersTime]);

  const clickedOnSave = useCallback(() => {
    if (name.length === 0) {
      setError('Please enter valid Name.');
      return;
    }

    if (phoneNo.length === 0) {
      setError('Please enter valid Phone No.');
      return;
    }

    if (pickupDate == null) {
      setError('Please enter a valid Pick Up Date and Time.');
      return;
    }

    const timeErrors = checkPickupTime(false);
    console.log(timeErrors);

    if (timeErrors.length > 0) {
      setError(timeErrors);
      return;
    }

    const productsForOrder = [];

    Object.entries(categories).forEach(([, group]) => {
      const { WICCAT, products } = group;

      Object.entries(products).forEach(([, item]) => {
        const { prodkey } = item;

        const cartQty = parseInt(cartQtyByProduct[prodkey]) || 0;

        if (cartQty > 0) {
          productsForOrder.push({
            id: prodkey,
            cat_id: WICCAT,
            qty: cartQty,
          });
        }
      });
    });

    const itemsByCat = [productsForOrder];
    const pickupAt = `${Moment(pickupDate, 'LL').format('YYYY-MM-DD')}T${pickupTime}${Moment.tz(pickupDate, 'LL', storeTimezone).format('Z')}`;

    const orderDetails = {
      store_no: selectedStore.store_no,
      name,
      phone_number: formatPhoneNumber(`+${phoneNo}`),
      pickup_at: pickupAt,
      is_curbside_pick_up: isCurbSidePickUp,
      send_text_confirmation: (sendTextConfirmation === '1') ? 1 : 0,
      items_by_cat: itemsByCat,
    };

    setIsLoading(true);

    dispatch(OrdersDataOperations.sendOrder(orderDetails)).then(() => {
      history.replace('/thank-you');
    }).catch((orderError) => {
      setError(orderError);
      setIsLoading(false);
    });
  }, [cartQtyByProduct, categories, checkPickupTime, dispatch, history, isCurbSidePickUp, name, phoneNo, pickupDate, pickupTime, selectedStore.store_no, sendTextConfirmation, storeTimezone, usersTime]);

  // useLayoutEffect(() => {
  // checkPickupTime();
  // }, [checkPickupTime, pickupDate, pickupTime, usersTime]);

  let content = '';

  if (isLoading) {
    content = (<span className="loadingSpinner"><i className="fas fa-spinner fa-spin" /></span>);
  } else if (storeIsOpen) {
    content = (
      <div className="list order-info-list">
        {haveItemsInTheCart ? (
          <Form onSubmit={(e) => { e.preventDefault(); }}>
            <FormGroup className="name-wrapper">
              <Label>First Name:</Label>
              <Input type="text" {...bindName} />
            </FormGroup>
            <FormGroup className="phone-wrapper">
              <Label>Phone No:</Label>
              <PhoneInput
                country="us"
                onlyCountries={['us', 'mx']}
                value={phoneNo}
                onChange={setPhoneNo}
              />
            </FormGroup>
            <div className="pickup-time-wrapper">
              <strong>Order Pickup Date</strong>
              {pickupDates.map((availableDate) => (
                <FormGroup key={availableDate} check>
                  <Label check>
                    <Input
                      type="radio"
                      value={availableDate}
                      checked={availableDate === pickupDate}
                      onChange={(e) => setPickupDate(e.target.value)}
                    />{' '}
                    {availableDate}
                  </Label>
                </FormGroup>
              ))}
            </div>
            <div className="pickup-time-wrapper">
              <strong>Order Pickup Time</strong>
              {Object.entries(pickupTimes).map(([availableTime, availableTimeText]) => (
                <FormGroup key={availableTime} check>
                  <Label check>
                    <Input
                      type="radio"
                      value={availableTime}
                      checked={availableTime === pickupTime}
                      onChange={(e) => setPickupTime(e.target.value)}
                    />{' '}
                    {availableTimeText}
                  </Label>
                </FormGroup>
              ))}
            </div>
            <h6>Pickup Type</h6>
            <div className="list pickupTypeWrapper">
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    value="0"
                    checked={isCurbSidePickUp === '0'}
                    onChange={(e) => setIsCurbSidePickUp(e.target.value)}
                  />{' '}
                  In Store
                </Label>
              </FormGroup>

              {selectedStoreAllowsCurbSidePickUp && (
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      value="1"
                      checked={isCurbSidePickUp === '1'}
                      onChange={(e) => setIsCurbSidePickUp(e.target.value)}
                    />{' '}
                    Curbside
                  </Label>
                </FormGroup>
              )}
            </div>
            <FormGroup className="textConfirmationWrapper" check>
              <Label check>
                <Input
                  type="checkbox"
                  value="1"
                  checked={sendTextConfirmation === '1'}
                  onChange={(e) => setSendTextConfirmation(e.target.checked ? '1' : '0')}
                />{' '}
                Please send me text confirmation of my order
              </Label>
            </FormGroup>
            <Button block color="primary" className="order-save-btn" onClick={clickedOnSave}>
              <div className="icon">
                <i className="fas fa-check-double" />
              </div>
              Submit Order
            </Button>
          </Form>
        ) : (
          <div className="m-b-1">You have not added any items to your cart yet.</div>
        )}
        <CancelButton className="m-t-1" />
      </div>
    );
  } else {
    content = (<Alert color="danger">The store you selected is not open today or tomorrow. Please go back and select a different location.</Alert>);
  }

  return (
    <div className="OrderInfo">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container">
          <SweetAlert
            show={error.toString().length > 0}
            type="error"
            text={error}
            confirmButtonClass="btn btn-primary"
            onConfirm={() => setError('')}
          />
          {content}
        </div>
      </div>
    </div>
  );
}

OrderInfo.propTypes = {
  history: PropTypes.object,
};

OrderInfo.defaultProps = {
  history: null,
};

export default withRouter(OrderInfo);
